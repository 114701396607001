<template>
  <CreateUpdateTemplate
    :customClass="'lead-update'"
    v-if="getPermission('lead:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update {{ leadCreate.title }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Lead
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="leadForm"
        v-model="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Lead Title</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Title"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.title"
                      dense
                      filled
                      label="Title"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(leadCreate.title, 'Lead Title'),
                        validateRules.minLength(
                          leadCreate.title,
                          'Lead Title',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.title,
                          'Lead Title',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Customer Name</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Name"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.name"
                      label="Name"
                      :rules="[
                        validateRules.required(leadCreate.name, 'Name'),
                        validateRules.minLength(leadCreate.name, 'Name', 1),
                        validateRules.maxLength(leadCreate.name, 'Name', 100),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Customer Company
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Company"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.company"
                      dense
                      filled
                      label="Company"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.company,
                          'Company',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.company,
                          'Company',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Customer Email</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Email"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.email"
                      dense
                      filled
                      label="Email"
                      :rules="[
                        validateRules.validEmail(leadCreate.email, 'Email'),
                        validateRules.minLength(leadCreate.email, 'Email', 1),
                        validateRules.maxLength(leadCreate.email, 'Email', 100),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Customer Phone No.
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Phone Number"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.phone_number"
                      dense
                      filled
                      label="Phone Number"
                      v-mask="currentPhoneMask"
                      :rules="[
                        validateRules.required(
                          leadCreate.phone_number,
                          'Phone Number'
                        ),
                        validateRules.minLength(
                          leadCreate.phone_number,
                          'Phone Number',
                          2
                        ),
                        validateRules.maxLength(
                          leadCreate.phone_number,
                          'Phone Number',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Customer Website
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Website"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.website"
                      dense
                      filled
                      label="Website"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.website,
                          'Website',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.website,
                          'Website',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Lead Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Lead Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Lead Date'"
                      v-model="leadCreate.lead_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">
                    Last Contacted Date
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Last Contacted Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      clearable
                      :pageLoading.sync="pageLoading"
                      :placeholder="'Last Contacted Date'"
                      v-model="leadCreate.last_contact"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Status</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Status"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="statusList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.status"
                      label="Status"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Status Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Source</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Source"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="sourceList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.source"
                      label="Source"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Source Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Lead number</td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-4"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model="leadCreate.barcode"
                        label="Lead number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Address</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Address"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.address"
                      dense
                      filled
                      label="Address"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          leadCreate.address,
                          'Address',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.address,
                          'Address',
                          100
                        ),
                      ]"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Country</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Country"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.country"
                      dense
                      filled
                      label="Country"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.country,
                          'Country',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.country,
                          'Country',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">State</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="State"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.state"
                      dense
                      filled
                      label="State"
                      :rules="[
                        validateRules.minLength(leadCreate.state, 'State', 1),
                        validateRules.maxLength(leadCreate.state, 'State', 100),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">City</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="City"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.city"
                      dense
                      filled
                      label="City"
                      :rules="[
                        validateRules.minLength(leadCreate.city, 'City', 1),
                        validateRules.maxLength(leadCreate.city, 'City', 100),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Postal Code</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Postal Code"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.zip"
                      dense
                      filled
                      label="Postal Code"
                      v-mask="'############'"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Extra</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Extra"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="extraList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.extra"
                      label="Extra"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Extra Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Lead Value</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Lead Value"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model="leadCreate.lead_value"
                      dense
                      filled
                      label="Lead Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Assigned</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Assigned"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="userList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="leadCreate.assigned_user"
                      label="Assigned"
                      solo
                      flat
                      item-color="cyan"
                      item-text="full_name"
                      item-value="id"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No User Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="leadCreate.description" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                :attachments.sync="attachments"
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "lead-create",
  title: "Create Lead",
  data() {
    return {
      lead: 0,
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      statusList: new Array(),
      sourceList: new Array(),
      extraList: new Array(),
      userList: new Array(),
      attachments: new Array(),
      leadCreate: new Object({
        reference: null,
        assigned_user: null,
        name: null,
        company: null,
        email: null,
        website: null,
        phone_number: null,
        country: null,
        zip: null,
        city: null,
        state: null,
        address: null,
        title: null,
        description: null,
        status: null,
        source: null,
        extra: null,
        lead_value: null,
        lead_date: null,
        last_contact: null,
        attachments: [],
      }),
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.leadCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/options",
        })
        .then(({ data }) => {
          _this.statusList = data.status_list;
          _this.sourceList = data.sources;
          _this.extraList = data.extras;
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.leadForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.leadCreate.reference || null,
        assigned_user: _this.leadCreate.assigned_user || null,
        name: _this.leadCreate.name || null,
        company: _this.leadCreate.company || null,
        email: _this.leadCreate.email || null,
        website: _this.leadCreate.website || null,
        phone_number: _this.leadCreate.phone_number || null,
        country: _this.leadCreate.country || null,
        zip: _this.leadCreate.zip || null,
        city: _this.leadCreate.city || null,
        state: _this.leadCreate.state || null,
        address: _this.leadCreate.address || null,
        title: _this.leadCreate.title || null,
        description: _this.leadCreate.description || null,
        status: _this.leadCreate.status || null,
        source: _this.leadCreate.source || null,
        extra: _this.leadCreate.extra || null,
        lead_value: _this.leadCreate.lead_value || null,
        lead_date: _this.leadCreate.lead_date || null,
        last_contact: _this.leadCreate.last_contact || null,
        attachments: _this.leadCreate.attachments || [],
      });

      _this.$store
        .dispatch(PUT, {
          url: "leads/" + _this.lead,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("lead.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLead() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.leadCreate = new Object({
            barcode: data.barcode || null,
            reference: data.reference || null,
            assigned_user: data.assigned_user ? data.assigned_user.id : null,
            name: data.name || null,
            company: data.company || null,
            email: data.email || null,
            website: data.website || null,
            phone_number: data.phone_number || null,
            country: data.country || null,
            zip: data.zip || null,
            city: data.city || null,
            state: data.state || null,
            address: data.address || null,
            title: data.title || null,
            description: data.description || null,
            status: data.status || null,
            source: data.source ? data.source.id : null,
            extra: data.extra ? data.extra.id : null,
            lead_value: data.lead_value || null,
            lead_date: data.lead_date || null,
            last_contact: data.last_contact || null,
            attachments: [],
          });
          _this.attachments = data.attachments;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lead", route: "lead" },
      { title: "Update" },
    ]);

    _this.lead = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.lead <= 0) {
      _this.goBack();
    }

    _this.getLead();
  },
};
</script>
